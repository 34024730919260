import React, { useState } from "react";
import screenshot1 from '../assests/screenshots/screenshot1.webp'
import screenshot2 from '../assests/screenshots/screenshot2.webp'
import screenshot3 from '../assests/screenshots/screenshot3.webp'
import screenshot4 from '../assests/screenshots/screenshot4.webp'
import screenshot5 from '../assests/screenshots/screenshot5.webp'
import screenshot6 from '../assests/screenshots/screenshot6.webp'
import screenshot7 from '../assests/screenshots/screenshot7.webp'
import screenshot8 from '../assests/screenshots/screenshot8.webp'
import screenshot9 from '../assests/screenshots/screenshot9.webp'



const ScreenShot = () => {

    return (

        <>

            <div className="container-fluid d-none d-sm-none d-md-none d-lg-block">
            <h6 className="text-white px-4">Screenshots</h6>
                <div className="col-12">
                    <div className="row mb-5">
                        <div className="col-6 px-4">
                            <img src={screenshot1} width="100%" ></img>
                        </div>
                        <div className="col-6 ps-4">
                            <span className="text-white" style={{ fontWeight: "bold", fontSize: "20px" }}>Please indicate your preferred color theme: black or white?"</span><br></br>
                            <span className="text-white mt-3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Black Theme: Choose the sleek sophistication of the black theme. With its dark canvas, this theme offers a modern and refined ambiance, perfect for those who appreciate a contemporary and stylish aesthetic. The black background provides a striking contrast, making colors and content pop with intensity and depth.</span><br></br>
                            <span className="text-white mt-3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. White Theme: Opt for the timeless elegance of the white theme. Featuring a clean and bright backdrop, this theme radiates a sense of simplicity and clarity, ideal for users who prefer a fresh and minimalist design. The white background lends a sense of openness, allowing content to stand out with crispness and clarity.</span>
                        </div>
                    </div>
                    <div className="row mb-5 mt-5">
                        <div className="col-6 ps-4">
                        <span className="text-white" style={{ fontWeight: "bold", fontSize: "20px" }}>Both swipe controls and joysticks are available for controlling the hero Pacman.</span><br></br>
                        <span className="text-white mt-3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Enjoy ultimate control over the iconic hero Pacman with our versatile control options. Navigate through the vibrant mazes with ease using either swipe controls or joysticks, tailored to suit your preferred style of play. Whether you prefer the fluidity of swiping gestures or the precision of joystick movements, both options are available, ensuring a seamless and immersive gaming experience. With intuitive controls at your fingertips, you have the power to guide Pacman through every twist and turn of the maze, devouring pellets and outmaneuvering ghosts with precision and finesse.</span> <br></br>                     
                        </div>
                        <div className="col-6 px-4">
                        <img src={screenshot4} width="100%" ></img>
                        </div>
                    </div>
                    <div className="row mb-5">
                    <div className="col-6 px-4">
                        <img src={screenshot2} width="100%" ></img>
                        </div>
                        <div className="col-6 ps-4">
                        <span className="text-white" style={{ fontWeight: "bold", fontSize: "20px" }}>Choose a Pacman hero character from a diverse selection of heroes.</span><br></br>
                        <span className="text-white mt-3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When selecting a Pacman hero character from a diverse selection, you're essentially choosing the persona you'll embody as you navigate through the maze-filled world of Pacmania. Each hero character offers a unique visual aesthetic, personality traits, and possibly even special abilities or attributes that can impact gameplay.</span> <br></br>                     
                        <span className="text-white mt-3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In essence, the option to choose a Pacman hero character from a diverse selection expands the possibilities for player immersion, representation, and storytelling within the Pacmania universe. It transforms the classic arcade experience into a more inclusive and personalized adventure for players of all backgrounds and interests.</span>
                        </div>
                    </div>
                    <div className="row mb-5 mt-5">
                      <div className="col-6 ps-4">
                            <span className="text-white" style={{ fontWeight: "bold", fontSize: "20px" }}>There are many enemies in the game for Pacman to confront</span><br></br>
                            <span className="text-white mt-3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the dynamic world of Pacmania, Pacman encounters a diverse array of adversaries, each presenting a unique obstacle to overcome in his quest through the maze. From the iconic quartet of ghosts—Blinky, Pinky, Inky, Clyde and many more —to newly introduced challengers, the game is teeming with enemies for Pacman to confront.</span><br></br>
                        </div>
                        <div className="col-6 px-4">
                            <img src={screenshot5} width="100%" ></img>
                        </div>
                    </div>

                    <div className="row mb-5">
                    <div className="col-6 px-4">
                        <img src={screenshot6} width="100%" ></img>
                        </div>
                        <div className="col-6 ps-4">
                        <span className="text-white" style={{ fontWeight: "bold", fontSize: "20px" }}>Many power-ups available for use within the game.</span><br></br>
                        <span className="text-white mt-3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pacmania is brimming with an abundance of power-ups, each offering unique abilities and advantages to aid Pacman in his quest through the maze. These power-ups provide exciting opportunities to turn the tide of gameplay in Pacman's favor, adding depth and excitement to the experience.</span> <br></br>                     
                        <span className="text-white mt-3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From the classic speed boost, which allows Pacman to dart through the maze with lightning-fast agility, to temporary invincibility, which grants him immunity from the pesky ghosts' pursuit, the power-ups in Pacmania offer a diverse range of benefits to players.</span>
                        </div>
                    </div>
                    <div className="row mb-5 mt-5">
                      <div className="col-6 ps-4">
                            <span className="text-white" style={{ fontWeight: "bold", fontSize: "20px" }}>A wide variety of edible items for Pacman to consume</span><br></br>
                            <span className="text-white mt-3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In Pacmania, the maze is a veritable buffet for Pacman, offering a wide variety of edible delights for him to consume as he navigates through its twists and turns. From the classic pellets scattered throughout the maze to larger power pellets strategically placed in the corners, Pacman must gobble up these tasty treats to progress through the game.</span><br></br>
                            <span className="text-white mt-3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But the culinary adventure doesn't end there! Along the way, Pacman may encounter an assortment of bonus fruits and delectable snacks, each offering its own unique flavor and reward. Whether it's a juicy cherry, a ripe strawberry, or even a savory pretzel, these bonus items add an extra layer of excitement and challenge to the gameplay, tempting players to explore every corner of the maze in search of their next delicious discovery.</span>
                        </div>
                        <div className="col-6 px-4">
                            <img src={screenshot7} width="100%" ></img>
                        </div>
                    </div>
                    <div className="row mb-5">
                    <div className="col-6 px-4">
                        <img src={screenshot3} width="100%" ></img>
                        </div>
                        <div className="col-6 ps-4">
                        <span className="text-white" style={{ fontWeight: "bold", fontSize: "20px" }}>There are hundreds of distinct levels to play</span><br></br>
                        <span className="text-white mt-3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Embark on an epic journey through Pacmania's vast universe, where adventure knows no bounds with hundreds of distinct levels to conquer. Each level presents its own unique maze layout, challenges, and surprises, ensuring endless hours of thrilling gameplay. Whether you're a seasoned player seeking new challenges or a newcomer eager to explore, there's always a fresh adventure awaiting you in every level of Pacmania</span> <br></br>                     
                        </div>
                    </div>
                    <div className="row mb-5 mt-5">
                      <div className="col-6 ps-4">
                            <span className="text-white" style={{ fontWeight: "bold", fontSize: "20px" }}>The gameplay is highly intriguing and exceptionally addictive</span><br></br>
                            <span className="text-white mt-3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pacmania offers an irresistibly captivating gameplay experience, drawing players into its world with its highly intriguing and exceptionally addictive mechanics. With each twist and turn of the maze, the challenge intensifies, keeping players on the edge of their seats as they strive for mastery. The addictive nature of the game ensures that players are continually drawn back for more, unable to resist the thrill of navigating Pacman through each level, gobbling up pellets, and evading ghosts. Prepare to lose track of time as you immerse yourself in the immersive and addictive gameplay of Pacmania.</span><br></br>
                        </div>
                        <div className="col-6 px-4">
                            <img src={screenshot8} width="100%" ></img>
                        </div>
                    </div>
                    <div className="row mb-5">
                    <div className="col-6 px-4">
                        <img src={screenshot9} width="100%" ></img>
                        </div>
                        <div className="col-6 ps-4">
                        <span className="text-white" style={{ fontWeight: "bold", fontSize: "20px" }}>Play increasingly complex levels</span><br></br>
                        <span className="text-white mt-3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As you progress through Pacmania, you'll find yourself traversing increasingly complex levels that push your skills to the limit. Each level introduces new challenges, from intricate maze designs to faster and more aggressive ghosts. You'll need to sharpen your reflexes and hone your strategy to navigate these challenging stages successfully.</span> <br></br>   
                        <span className="text-white mt-3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;At first, you'll encounter relatively straightforward mazes with sparse obstacles and slower-moving ghosts. But as you advance, the mazes become more labyrinthine, filled with twists, turns, and dead ends that require careful navigation to avoid getting trapped. Meanwhile, the ghosts become more cunning, employing advanced tactics to corner you and cut off your escape routes.</span>                                     
                        </div>
                    </div>
                  
                </div>
            </div>
            <div className="container-fluid d-block d-sm-block d-md-block d-lg-none">
            {/* <h6 className="text-white">Screenshots</h6> */}
                <div className="col-12">
                    <div className="row mb-4">
                        <span className="text-white" style={{ fontWeight: "bold", fontSize: "16px" }}>Please indicate your preferred color theme: black or white?"</span><br></br>
                            <img src={screenshot1} width="100%" ></img>
                            <span className="text-white mt-3"  style={{ fontSize: "15px", fontWeight: "400" }}  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Black Theme: Choose the sleek sophistication of the black theme. With its dark canvas, this theme offers a modern and refined ambiance, perfect for those who appreciate a contemporary and stylish aesthetic. The black background provides a striking contrast, making colors and content pop with intensity and depth.</span><br></br>
                            <span className="text-white mt-3"  style={{ fontSize: "15px", fontWeight: "400" }}  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. White Theme: Opt for the timeless elegance of the white theme. Featuring a clean and bright backdrop, this theme radiates a sense of simplicity and clarity, ideal for users who prefer a fresh and minimalist design. The white background lends a sense of openness, allowing content to stand out with crispness and clarity.</span>
                    </div>
                    <div className="row mb-4">
                        <span className="text-white" style={{ fontWeight: "bold", fontSize: "16px" }}>Both swipe controls and joysticks are available for controlling the hero Pacman.</span><br></br>
                            <img src={screenshot4} width="100%" ></img>
                            <span className="text-white mt-3"  style={{ fontSize: "15px", fontWeight: "400" }}  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Enjoy ultimate control over the iconic hero Pacman with our versatile control options. Navigate through the vibrant mazes with ease using either swipe controls or joysticks, tailored to suit your preferred style of play. Whether you prefer the fluidity of swiping gestures or the precision of joystick movements, both options are available, ensuring a seamless and immersive gaming experience. With intuitive controls at your fingertips, you have the power to guide Pacman through every twist and turn of the maze, devouring pellets and outmaneuvering ghosts with precision and finesse.</span><br></br>
                    </div>
                    <div className="row mb-4">
                        <span className="text-white" style={{ fontWeight: "bold", fontSize: "16px" }}>Choose a Pacman hero character from a diverse selection of heroes.</span><br></br>
                            <img src={screenshot2} width="100%" ></img>
                            <span className="text-white mt-3"  style={{ fontSize: "15px", fontWeight: "400" }}  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When selecting a Pacman hero character from a diverse selection, you're essentially choosing the persona you'll embody as you navigate through the maze-filled world of Pacmania. Each hero character offers a unique visual aesthetic, personality traits, and possibly even special abilities or attributes that can impact gameplay.</span><br></br>
                            <span className="text-white mt-3"  style={{ fontSize: "15px", fontWeight: "400" }}  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In essence, the option to choose a Pacman hero character from a diverse selection expands the possibilities for player immersion, representation, and storytelling within the Pacmania universe. It transforms the classic arcade experience into a more inclusive and personalized adventure for players of all backgrounds and interests.</span>
                    </div>
                    <div className="row mb-4">
                        <span className="text-white" style={{ fontWeight: "bold", fontSize: "16px" }}>There are many enemies in the game for Pacman to confront</span><br></br>
                            <img src={screenshot5} width="100%" ></img>
                            <span className="text-white mt-3"  style={{ fontSize: "15px", fontWeight: "400" }}  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the dynamic world of Pacmania, Pacman encounters a diverse array of adversaries, each presenting a unique obstacle to overcome in his quest through the maze. From the iconic quartet of ghosts—Blinky, Pinky, Inky, Clyde and many more —to newly introduced challengers, the game is teeming with enemies for Pacman to confront.</span><br></br>
                    </div>
                    <div className="row mb-4">
                        <span className="text-white" style={{ fontWeight: "bold", fontSize: "16px" }}>Many power-ups available for use within the game.</span><br></br>
                            <img src={screenshot6} width="100%" ></img>
                            <span className="text-white mt-3"  style={{ fontSize: "15px", fontWeight: "400" }}  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pacmania is brimming with an abundance of power-ups, each offering unique abilities and advantages to aid Pacman in his quest through the maze. These power-ups provide exciting opportunities to turn the tide of gameplay in Pacman's favor, adding depth and excitement to the experience.</span><br></br>
                            <span className="text-white mt-3"  style={{ fontSize: "15px", fontWeight: "400" }}  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From the classic speed boost, which allows Pacman to dart through the maze with lightning-fast agility, to temporary invincibility, which grants him immunity from the pesky ghosts' pursuit, the power-ups in Pacmania offer a diverse range of benefits to players.</span>
                    </div>
                    <div className="row mb-4">
                        <span className="text-white" style={{ fontWeight: "bold", fontSize: "16px" }}>A wide variety of edible items for Pacman to consume</span><br></br>
                            <img src={screenshot7} width="100%" ></img>
                            <span className="text-white mt-3"  style={{ fontSize: "15px", fontWeight: "400" }}  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In Pacmania, the maze is a veritable buffet for Pacman, offering a wide variety of edible delights for him to consume as he navigates through its twists and turns. From the classic pellets scattered throughout the maze to larger power pellets strategically placed in the corners, Pacman must gobble up these tasty treats to progress through the game.</span><br></br>
                            <span className="text-white mt-3"  style={{ fontSize: "15px", fontWeight: "400" }}  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But the culinary adventure doesn't end there! Along the way, Pacman may encounter an assortment of bonus fruits and delectable snacks, each offering its own unique flavor and reward. Whether it's a juicy cherry, a ripe strawberry, or even a savory pretzel, these bonus items add an extra layer of excitement and challenge to the gameplay, tempting players to explore every corner of the maze in search of their next delicious discovery.</span>
                    </div>
                    <div className="row mb-4">
                        <span className="text-white" style={{ fontWeight: "bold", fontSize: "16px" }}>There are hundreds of distinct levels to play</span><br></br>
                            <img src={screenshot3} width="100%" ></img>
                            <span className="text-white mt-3"  style={{ fontSize: "15px", fontWeight: "400" }}  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Embark on an epic journey through Pacmania's vast universe, where adventure knows no bounds with hundreds of distinct levels to conquer. Each level presents its own unique maze layout, challenges, and surprises, ensuring endless hours of thrilling gameplay. Whether you're a seasoned player seeking new challenges or a newcomer eager to explore, there's always a fresh adventure awaiting you in every level of Pacmania</span><br></br>
                    </div>
                    <div className="row mb-4">
                        <span className="text-white" style={{ fontWeight: "bold", fontSize: "16px" }}>The gameplay is highly intriguing and exceptionally addictive</span><br></br>
                            <img src={screenshot8} width="100%" ></img>
                            <span className="text-white mt-3"  style={{ fontSize: "15px", fontWeight: "400" }}  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pacmania offers an irresistibly captivating gameplay experience, drawing players into its world with its highly intriguing and exceptionally addictive mechanics. With each twist and turn of the maze, the challenge intensifies, keeping players on the edge of their seats as they strive for mastery. The addictive nature of the game ensures that players are continually drawn back for more, unable to resist the thrill of navigating Pacman through each level, gobbling up pellets, and evading ghosts. Prepare to lose track of time as you immerse yourself in the immersive and addictive gameplay of Pacmania.</span><br></br>
                    </div>
                    <div className="row mb-4">
                        <span className="text-white" style={{ fontWeight: "bold", fontSize: "16px" }}>Play increasingly complex levels</span><br></br>
                            <img src={screenshot9} width="100%" ></img>
                            <span className="text-white mt-3"  style={{ fontSize: "15px", fontWeight: "400" }}  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As you progress through Pacmania, you'll find yourself traversing increasingly complex levels that push your skills to the limit. Each level introduces new challenges, from intricate maze designs to faster and more aggressive ghosts. You'll need to sharpen your reflexes and hone your strategy to navigate these challenging stages successfully.</span><br></br>
                            <span className="text-white mt-3"  style={{ fontSize: "15px", fontWeight: "400" }}  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;At first, you'll encounter relatively straightforward mazes with sparse obstacles and slower-moving ghosts. But as you advance, the mazes become more labyrinthine, filled with twists, turns, and dead ends that require careful navigation to avoid getting trapped. Meanwhile, the ghosts become more cunning, employing advanced tactics to corner you and cut off your escape routes.</span>
                    </div>
                  
                </div>
            </div>

        </>

    )
}

export default ScreenShot;