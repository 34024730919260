
import React, {useState} from "react";
import JoinCode from "./codejoins";
import PacMania from "../gamescreen/pacmania";
// import { getAnalytics, logEvent } from "firebase/analytics";


const MainCode = () => {
    const [showGame, setShowGame] = useState(false);
    // const analytics = getAnalytics();

    const handleButtonClick = () => {
        // logEvent(analytics, "Pacmania Clicked");
        setShowGame(!showGame);
    };
  
    return (
      <div>
        {showGame ? (
          <PacMania onButtonClick={handleButtonClick} />
        ) : (
          <JoinCode onButtonClick={handleButtonClick} />
        )}
      </div>
    );
  };
  
  export default MainCode;