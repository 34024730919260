import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import Advertisement_160X600 from "../advertisement/advertisement1";

const Description = () => {

    const navigate = useNavigate();

    const gohomescreen = () => {
        navigate('/');
    }



    return (

        <>
            <div className="row">
                <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                    <div className="text-white  stylestext px-lg-4 mx-lg-4 px-sm-2 mx-sm-2  mx-md-4 px-md-4 px-2 mx-2  py-3" >
                    <h6 className="mb-3" style={{ fontSize: "24px", fontWeight: "600" }}>Pacmania</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>"Pacmania" is an exhilarating journey into the heart of arcade gaming nostalgia, bringing the timeless classic Pacmania to life in a vibrant and modern package. Step into the shoes of the legendary Pacman hero as you navigate through a series of intricate mazes filled with pellets, power-ups, and colorful ghosts.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>With intuitive controls optimized for touchscreens, guide Pacmania through each maze, strategically gobbling up pellets while avoiding the relentless pursuit of the ghosts. But don't be fooled by their playful appearance - each ghost possesses unique behaviors and personalities, adding an extra layer of challenge and excitement to the gameplay.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>As you progress through the game, you'll encounter increasingly complex mazes and new challenges to overcome. Utilize power-ups strategically to turn the tide in your favour, from speed boosts to temporary invincibility, as you strive to achieve the highest score possible.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>Pacmania boasts stunning visuals and dynamic sound effects that capture the essence of the original arcade experience while delivering a fresh and modern twist. Immerse yourself in the nostalgia of classic arcade gaming as you embark on a thrilling adventure through the colorful world of Pacmania.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>Whether you're a seasoned Pacmania veteran or a newcomer to the franchise, Pacmania offers something for everyone - from casual gamers looking for a quick dose of fun to hardcore enthusiasts seeking a challenge. With its addictive gameplay, endless replay value, and timeless appeal, Pacmania is sure to become a favorite in your gaming collection.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>So gather your wits, sharpen your reflexes, and get ready for an unforgettable journey through the maze. Download Pacmania now and prepare to embark on the ultimate Pacmania adventure!"</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>Playing Pacmania is simple yet addictive!</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>1. Objective: The goal is to navigate Pacmania, a hero with a mouth, through a maze while eating all the pellets without being caught by the ghosts.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>2. Controls: You typically control Pacmania using Joystick or by swiping on a touchscreen device to move Pacmania in the corresponding direction: up, down, left, or right.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>3. Eating Pellets: Move Pacmania around the maze to eat all the small pellets (often called "Pac-Dots" or just "dots"). As Pacmania eats pellets, they disappear.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>4. Avoiding Ghosts: Be cautious of the ghosts (Blinky, Pinky, Inky, Clyde,etc), who roam the maze trying to catch Pacmania. If a ghost touches Pacmania, you lose a life.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>5. Power Pellets: In the corners of the maze, you'll find larger pellets called "power pellets" or "energizers." When Pacmania eats a power pellet, the ghosts turn color and become vulnerable for a short period. During this time, Pacmania can eat the ghosts to earn extra points.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>6. Fruit Bonuses: Occasionally, fruit and other bonus items appear in the maze. Eating these items awards additional points.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>7. Clearing the Maze: To complete a level, you must eat all the pellets in the maze. Once all the pellets are eaten, you advance to the next level, where the maze layout may change, and the game becomes more challenging.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>8. Lives and Score: You typically start the game with three lives. You earn points for eating pellets, fruits, and ghosts. The goal is to achieve the highest score possible before losing all your lives.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>9. Challenges: As you progress through the levels, the ghosts become faster and more aggressive, making it harder to avoid them. The game's difficulty increases with each level.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>10. Game Over: The game ends when you lose all your lives. Your final score is displayed, and you have the option to start a new game and try to beat your previous score.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>That's the basics of playing Pacmania! It's a simple yet addictive game that has entertained players for decades. So, grab those pellets, avoid those ghosts, and see how high you can score!</h6>

                <h6 style={{ fontSize: "18px", }} className="fontweight mb-3">How To Play?</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">1. To begin, click the Play Button.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">2. In Pacmania, utilize the controls to maneuver Pacmania in your preferred direction: up, down, left, or right.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3" >3. Gather eatables to boost your score and acquire powers to turn the enemies into eatables.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3" >4. Explore the skins option to access different Pacmania appearances.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3" >5. Immerse yourself in the classic arcade experience and relish the joy of gobbling up pellets as Pacmania!</h6>
                {/* <iframe allowfullscreen="" frameborder="0" width="168" height="138" src="https://www.youtube.com/embed/nVLuTV2GJ6g?&autoplay=1"></iframe> */}

                <iframe className="utubesize" src="https://www.youtube.com/embed/FNv-DhJtWuY" frameBorder="0" allowFullScreen></iframe>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Controls</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400", textDecoration: "underline" }}>DESKTOP : </h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>1. Joystick: Utilize the left mouse button to move Pacmania in the desired direction.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>2. <b>Keyboard :</b></h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> * UP: Use the UP arrow Key.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> * Down: Use the DOWN arrow Key.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> * Left: Use the RIGHT arrow Key.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> * Right: Use the LEFT arrow key.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400", textDecoration: "underline" }}>MOBILE : </h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> 1. Swipe: Swipe in the direction you want Pacmania to move.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> 2. Joystick: Drag the joystick in the direction you want Pacmania to move.</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Category</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Arcade, Classic</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Tags</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Classic arcade game, Pacmania, Maze navigation, Pellet eating, Ghost avoidance, Arrow keys, Touch controls, Outmaneuver ghosts, Power pellets, Extra points, Multiple levels, Unique maze layouts, Increasingly challenging, Thrilling gameplay, Addictive experience, Cultural icon, Greatest video games, Simple gameplay, Vibrant graphics, Memorable characters, Timeless classic.</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Release Date</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>6 February 2024</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Updates</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Build Number : 008</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>6 February 2024</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Developer</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Revolution Games Private Limited</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Platforms</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Web browser (desktop and mobile)</h6>

                    </div>
                </div>
  

                <div className=" col-lg-2 d-none d-sm-none d-md-none d-lg-block mt-5 " style={{ border: "1px solid black", height: "600px" }}>
                    <div className="d-flex align-items-center justify-content-center me-4">
                        <Advertisement_160X600 />
                    </div>

                </div>
            </div>
                          


        </>

    )
}

export default Description;