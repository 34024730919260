import React, {useState} from "react";
import '../App.css';
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import maze1 from '../assests/Mazes/mazes1.png'
import maze2 from '../assests/Mazes/mazes2.png'
import maze3 from '../assests/Mazes/mazes3.png'
import maze4 from '../assests/Mazes/mazes4.png'
import maze5 from '../assests/Mazes/mazes5.png'
import maze6 from '../assests/Mazes/mazes6.png'
import maze7 from '../assests/Mazes/mazes7.png'
import maze8 from '../assests/Mazes/mazes8.png'
import maze9 from '../assests/Mazes/mazes9.png'
import maze10 from '../assests/Mazes/mazes10.png'
import maze11 from '../assests/Mazes/mazes11.png'
import maze12 from '../assests/Mazes/mazes12.png'
import maze13 from '../assests/Mazes/mazes13.png'
import maze14 from '../assests/Mazes/mazes14.png'
import maze15 from '../assests/Mazes/mazes15.png'
import maze16 from '../assests/Mazes/mazes16.png'
import maze17 from '../assests/Mazes/mazes17.png'
import maze18 from '../assests/Mazes/mazes18.png'
import maze19 from '../assests/Mazes/mazes19.png'
import maze20 from '../assests/Mazes/mazes20.png'
import maze21 from '../assests/Mazes/mazes21.png'
import maze22 from '../assests/Mazes/mazes22.png'
import maze23 from '../assests/Mazes/mazes23.png'
import maze24 from '../assests/Mazes/mazes24.png'
import maze25 from '../assests/Mazes/mazes25.png'
import maze26 from '../assests/Mazes/mazes26.png'
import maze27 from '../assests/Mazes/mazes27.png'
import maze28 from '../assests/Mazes/mazes28.png'
import maze29 from '../assests/Mazes/mazes29.png'
import maze30 from '../assests/Mazes/mazes30.png'
import maze31 from '../assests/Mazes/mazes31.png'
import maze32 from '../assests/Mazes/mazes32.png'
import maze33 from '../assests/Mazes/mazes33.png'
import maze34 from '../assests/Mazes/mazes34.png'
import maze35 from '../assests/Mazes/mazes35.png'
import maze36 from '../assests/Mazes/mazes36.png'
import maze37 from '../assests/Mazes/mazes37.png'
import maze38 from '../assests/Mazes/mazes38.png'
import maze39 from '../assests/Mazes/mazes39.png'
import maze40 from '../assests/Mazes/mazes40.png'
import maze41 from '../assests/Mazes/mazes41.png'
import maze42 from '../assests/Mazes/mazes42.png'
import maze43 from '../assests/Mazes/mazes43.png'
import maze44 from '../assests/Mazes/mazes44.png'
import maze45 from '../assests/Mazes/mazes45.png'
import maze46 from '../assests/Mazes/mazes46.png'
import maze47 from '../assests/Mazes/mazes47.png'
import maze48 from '../assests/Mazes/mazes48.png'
import maze49 from '../assests/Mazes/mazes49.png'
import maze50 from '../assests/Mazes/mazes50.png'
import maze51 from '../assests/Mazes/mazes51.png'
import maze52 from '../assests/Mazes/mazes52.png'
import maze53 from '../assests/Mazes/mazes53.png'
import maze54 from '../assests/Mazes/mazes54.png'
import maze55 from '../assests/Mazes/mazes55.png'
import maze56 from '../assests/Mazes/mazes56.png'
import maze57 from '../assests/Mazes/mazes57.png'
import maze58 from '../assests/Mazes/mazes58.png'
import maze59 from '../assests/Mazes/mazes59.png'
import maze60 from '../assests/Mazes/mazes60.png'
import maze61 from '../assests/Mazes/mazes61.png'
import maze62 from '../assests/Mazes/mazes62.png'
import maze63 from '../assests/Mazes/mazes63.png'
import maze64 from '../assests/Mazes/mazes64.png'
import maze65 from '../assests/Mazes/mazes65.png'
import maze66 from '../assests/Mazes/mazes66.png'
import maze67 from '../assests/Mazes/mazes67.png'
import maze68 from '../assests/Mazes/mazes68.png'
import maze69 from '../assests/Mazes/mazes69.png'
import maze70 from '../assests/Mazes/mazes70.png'
import maze71 from '../assests/Mazes/mazes71.png'
import maze72 from '../assests/Mazes/mazes72.png'
import maze73 from '../assests/Mazes/mazes73.png'
import maze74 from '../assests/Mazes/mazes74.png'
import maze75 from '../assests/Mazes/mazes75.png'
import maze76 from '../assests/Mazes/mazes76.png'
import maze77 from '../assests/Mazes/mazes77.png'
import maze78 from '../assests/Mazes/mazes78.png'
import maze79 from '../assests/Mazes/mazes79.png'
import maze80 from '../assests/Mazes/mazes80.png'
import maze81 from '../assests/Mazes/mazes81.png'
import maze82 from '../assests/Mazes/mazes82.png'
import maze83 from '../assests/Mazes/mazes83.png'
import maze84 from '../assests/Mazes/mazes84.png'
import maze85 from '../assests/Mazes/mazes85.png'
import maze86 from '../assests/Mazes/mazes86.png'
import maze87 from '../assests/Mazes/mazes87.png'
import maze88 from '../assests/Mazes/mazes88.png'
import maze89 from '../assests/Mazes/mazes89.png'
import maze90 from '../assests/Mazes/mazes90.png'
import maze91 from '../assests/Mazes/mazes91.png'
import maze92 from '../assests/Mazes/mazes92.png'
import maze93 from '../assests/Mazes/mazes93.png'
import maze94 from '../assests/Mazes/mazes94.png'
import maze95 from '../assests/Mazes/mazes95.png'
import maze96 from '../assests/Mazes/mazes96.png'
import maze97 from '../assests/Mazes/mazes97.png'
import maze98 from '../assests/Mazes/mazes98.png'
import maze99 from '../assests/Mazes/mazes99.png'
import maze100 from '../assests/Mazes/mazes100.png'
import maze101 from '../assests/Mazes/mazes101.png'




const Mazes = () => {
    const navigate = useNavigate()

    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
  
    const openModal = (image) => {
      setSelectedImage(image);
      setShowModal(true);
    };
  
    const closeModal = () => {
      setSelectedImage(null);
      setShowModal(false);
    };
  
    const itemsmazes = [
        { id: 1, image: maze1 },
        { id: 2, image: maze2},
        { id: 3, image: maze3,  },
        { id: 4, image: maze4,  },
        { id: 5, image: maze5, },
        { id: 6, image: maze6 },
        { id: 7, image: maze7},
        { id: 8, image: maze8,  },
        { id: 9, image: maze9,  },
        { id: 10, image: maze10, },
        { id: 11, image: maze11 },
        { id: 12, image: maze12},
        { id: 13, image: maze13,  },
        { id: 14, image: maze14,  },
        { id: 15, image: maze15, },
        { id: 16, image: maze16 },
        { id: 17, image: maze17},
        { id: 18, image: maze18,  },
        { id: 19, image: maze19,  },
        { id: 20, image: maze20, },
        { id: 21, image: maze21 },
        { id: 22, image: maze22},
        { id: 23, image: maze23,  },
        { id: 24, image: maze24,  },
        { id: 25, image: maze25, },
        { id: 26, image: maze26 },
        { id: 27, image: maze27},
        { id: 28, image: maze28,  },
        { id: 29, image: maze29,  },
        { id: 30, image: maze30, },
        { id: 31, image: maze31 },
        { id: 32, image: maze32},
        { id: 33, image: maze33,  },
        { id: 34, image: maze34,  },
        { id: 35, image: maze35, },
        { id: 36, image: maze36 },
        { id: 37, image: maze37},
        { id: 38, image: maze38,  },
        { id: 39, image: maze39,  },
        { id: 40, image: maze40, },
        { id: 41, image: maze41 },
        { id: 42, image: maze42},
        { id: 43, image: maze43,  },
        { id: 44, image: maze44,  },
        { id: 45, image: maze45, },
        { id: 46, image: maze46 },
        { id: 47, image: maze47},
        { id: 48, image: maze48,  },
        { id: 49, image: maze49,  },
        { id: 50, image: maze50, },
        { id: 51, image: maze51 },
        { id: 52, image: maze52},
        { id: 53, image: maze53,  },
        { id: 54, image: maze54,  },
        { id: 55, image: maze55, },
        { id: 56, image: maze56 },
        { id: 57, image: maze57},
        { id: 58, image: maze58,  },
        { id: 59, image: maze59,  },
        { id: 60, image: maze60, },
        { id: 61, image: maze61 },
        { id: 62, image: maze62},
        { id: 63, image: maze63,  },
        { id: 64, image: maze64,  },
        { id: 65, image: maze65, },
        { id: 66, image: maze66 },
        { id: 67, image: maze67},
        { id: 68, image: maze68,  },
        { id: 69, image: maze69,  },
        { id: 70, image: maze70, },
        { id: 71, image: maze71 },
        { id: 72, image: maze72},
        { id: 73, image: maze73,  },
        { id: 74, image: maze74,  },
        { id: 75, image: maze75, },
        { id: 76, image: maze76 },
        { id: 77, image: maze77},
        { id: 78, image: maze78,  },
        { id: 79, image: maze79,  },
        { id: 80, image: maze80, },
        { id: 81, image: maze81 },
        { id: 82, image: maze82},
        { id: 83, image: maze83,  },
        { id: 84, image: maze84,  },
        { id: 85, image: maze85, },
        { id: 86, image: maze86 },
        { id: 87, image: maze87},
        { id: 88, image: maze88,  },
        { id: 89, image: maze89,  },
        { id: 90, image: maze90, },
        { id: 91, image: maze91 },
        { id: 92, image: maze92},
        { id: 93, image: maze93,  },
        { id: 94, image: maze94,  },
        { id: 95, image: maze95, },
        { id: 96, image: maze96 },
        { id: 97, image: maze97},
        { id: 98, image: maze98,  },
        { id: 99, image: maze99,  },
        { id: 100, image: maze100, },
        { id: 101, image: maze101, },

       
    ];

  
  

    return (

        <>
      <div className="row px-2 rowcol1">
        <h6 className="text-white">Maze</h6>
        {itemsmazes.map((item) => (
          <div key={item.id} className="col-1 col-sm-4 col-4 col-md-3" onClick={() => openModal(item.image)}>
            <img src={item.image} className="sliderimg p-2" alt={item.name} style={{cursor:"pointer"}}/>
          </div>
        ))}
      </div>

      <Modal show={showModal} onHide={closeModal} centered >
        <Modal.Header closeButton >
          <Modal.Title >Maze</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{background:"black"}}>
          <img src={selectedImage} alt="Clicked Maze" style={{width:"100%" , height:"100%"}}/>
        </Modal.Body>
      </Modal>
               


        </>

    )
}

export default Mazes;