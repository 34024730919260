import React from "react";
import '../App.css';
import rgilogo from '../assests/images/rginamelogowhite.webp'
import youtuber from '../assests/images/youtube.png'
import twitters from '../assests/images/twitter.png'
import facebooks from '../assests/images/facebook.png'
import instagrams from '../assests/images/instagram.png'
import linkdins from '../assests/images/linkdin.png'
import pinterests from '../assests/images/pinterest.png'

const FooterPortion = () => {

    return (
         <>
            <div><div className="footerbackground  ps-5  pe-5 px-5  bottom-0" style={{background:"#222222"}}>
                    <div className="row mx-3 pb-4" >
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 px-lg-0 ms-lg-0 px-md-0 ms-md-0 px-sm-0 ms-sm-0 px-0 ms-0">
                            <div className="d-flex align-items-center justify-content-center  ">
                                <img src={rgilogo} alt="rgilogo" width="200px" height="117px" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 " >
                            {/* <div className="d-flex align-items-center justify-content-center  mt-lg-5  mt-sm-0  mt-md-5  mt-0" >
                                <button className="buttonblack p-2"><img src={facebooks} alt="facebooks" width="20px" height="20px" /></button>
                                <button className="buttonblack p-2 "><img src={twitters} alt="twitters" width="25px" height="20px" /></button>
                                <button className="buttonblack p-2"><img src={linkdins} alt="linkdins" width="20px" height="20px" /></button>
                                <button className="buttonblack p-2"><img src={instagrams} alt="instagrams" width="20px" height="20px" /></button>
                                <button className="buttonblack p-2"><img src={pinterests} alt="pinterests" width="20px" height="20px" /></button>
                                <button className="buttonblack p-2"><img src={youtuber} alt="youtuber" width="25px" height="20px" /></button>
                            </div> */}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 pt-sm-3 pt-3 pt-md-3">
                            <div className="d-flex align-items-center justify-content-center">
                                <ul className="text-white"style={{listStyleType : "none"}}>
                                   <li className="mb-1" style={{cursor:"pointer"}}><a href="https://www.revolutiongamesindia.com/" target="_blank" rel="noopener noreferrer" style={{color:"white", textDecoration:"none"}}>Our Company</a></li>
                                    <li className="mb-1" style={{cursor:"pointer"}} ><a href="https://playrevolutiongames.com/about" target="_blank" rel="noopener noreferrer" style={{color:"white", textDecoration:"none"}}>About Us</a></li>
                                    <li className="mb-1" style={{cursor:"pointer"}} ><a href="https://playrevolutiongames.com/contact" target="_blank" rel="noopener noreferrer" style={{color:"white", textDecoration:"none"}}>Contact Us</a></li>
                                    <li className="mb-1" style={{cursor:"pointer"}} ><a href="https://playrevolutiongames.com/termsofservice" target="_blank" rel="noopener noreferrer" style={{color:"white", textDecoration:"none"}}>Terms Of Service</a></li>
                                    <li className="mb-1" style={{cursor:"pointer"}} ><a href="https://playrevolutiongames.com/privacypolicy" target="_blank" rel="noopener noreferrer" style={{color:"white", textDecoration:"none"}}>Privacy Policy</a></li>

                                </ul>
                            </div>
                        </div>
                       
                    </div>
                    <div className="px-5">
                        <hr style={{color:"white"}}></hr>
                    </div>
                    <div style={{ textAlign: "center", color:"white" }} className="pt-3 d-sm-none d-none d-md-none d-lg-block">
                        <h6>&copy;2023. All rights reserved. Revolution Games Private Limited</h6>

                    </div>
                    <div style={{ textAlign: "center", color:"white" }} className="pt-3 textsize d-sm-block d-block d-md-block d-lg-none">
                        <h6>&copy;2023. All rights reserved.</h6>
                        <h6> Revolution Games Private Limited</h6>

                    </div>

                </div>
            </div>

        </>

    )
}

export default FooterPortion;