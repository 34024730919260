import React, { useState, useEffect, useRef, useCallback, lazy, Suspense } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import FooterPortion from "../headerfooter/footersection";
import { useLocation, useNavigate } from 'react-router-dom';
// import BottomListGame from "./bottomlistgame";
import rgnamelogos from '../assests/images/rginamelogowhite.webp';
import close from '../assests/images/closebutton.webp';
import Addadds from "../advertisement/Adsanotherone";
import BottomListGameLeft from "./othergamesleft";
import pacmania from '../assests/images/0018_pacmania.webp'
import { MdScreenRotation } from "react-icons/md";
import { BiExitFullscreen } from 'react-icons/bi'
import BottomListGameRight from "./Othergamesright";
import continueyellow2 from '../assests/images/continueyellow2.webp';
import Advertisement3 from "../advertisement/advertisement3";
// import secureLocalStorage from "react-secure-storage";
import { connect, useDispatch, useSelector } from 'react-redux'
// import { getAnalytics, logEvent } from "firebase/analytics";
import BottomMenu from "./commonmenu";
import rgilogo from "../assests/images/rginamelogowhite.webp"
import rgblacklogo from '../assests/images/rglogo.webp'
import { SlArrowLeft } from "react-icons/sl";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
// import SearchGamePlay from "./searchgameplayscreen";
import Othergames from "./othergames";
const StartingSplashDiv = lazy(() => import('./startingsplashall'));



const PacMania = ({onButtonClick}) => {
  const { unityProvider, isLoaded, sendMessage, unload, addEventListener, removeEventListener, loadingProgression } = useUnityContext({
    loaderUrl: "/0018_PacMania/0018_PacMania.loader.js",
    dataUrl: "/0018_PacMania/0018_PacMania.data.br",
    frameworkUrl: "/0018_PacMania/0018_PacMania.framework.js.br",
    codeUrl: "/0018_PacMania/0018_PacMania.wasm.br",

  });


  function sendmessagetounity1() {
    sendMessage("Winpopuppanel", "PlayNextLevelMessageFromWeb", "Next Level");
  }

  const [isGameOver, setIsGameOver] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [buttonCounter, setButtonCounter] = useState(5);
  const containerRef = useRef(null);
  const [showButton, setShowButton] = useState(false);
  const [videoVisible, setVideoVisible] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const loaded = useRef(null);
  const [refreshAdd, setRefreshAdd] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const navigate = useNavigate();
  const [isButtonRed, setButtonRed] = useState(false);
  const [showplaybutton, setShowplaybutton] = useState(true);
  const [loadingProgressionValue, setLoadingProgressionValue] = useState(0);
  const [loadingProgressionLoadedFirst90, setLoadingProgressionLoadedFirst90] = useState(false);
  const [loadingRandomText, setLoadingRandomText] = useState('Loading Assets...');
  const [levelNo, setLevelNumber] = useState("");
  const [delayad, setDelayad] = useState(false)
  const [loading, setLoading] = useState(true);
  const [showsplash, setShowsplash] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isMobile, setIsMobile] = useState(false)
  const [delayadvertisement, setDelayadvertisement] = useState(true)
  const [finishStatus, setfinishStatus] = useState(false);
  const [showRotateYourScreen, setShowRotateYourScreen] = useState(false)
  const [isIpadUnity, setIsPadUnity] = useState(false)
  const [isAndroidunity, setIsAndroidUnity] = useState(false)
  const [percent, setPercent] = useState(10);
  const [startingflow, setStartingflow] = useState(false)
  const [isiPhoneUnity, setIsiPhoneUnity] = useState(false);

  function sendmessagetounity() {
    sendMessage("ResponseFromWeb", "MyFunction", "Hi from web");
  }

  const gohomescreen = () => {
    sendmessagetounity()
    setTimeout(() => {
      // navigate('/');
      onButtonClick()
    }, 1000);
  }



  const numericPart = levelNo.match(/\d+/);
  const levelValue = numericPart ? numericPart[0] : "";
  // const analytics = getAnalytics();
  const Advertisementafterplay = () => {

    // logEvent(analytics, "Pacmania Level_" + levelValue);
    sendmessagetounity1()
    setIsGameOver(false)
    setRefreshAdd(true)

    if (isAndroidunity && startingflow) {
      startSendMsgAndroid()
    }
    else if (isiPhoneUnity && startingflow) {
      startSendMsgiPhone()
    }
  }

  const handleGameOver = useCallback((message, gameId, levelNo) => {
    setIsGameOver(true);
    setLevelNumber(levelNo)
    setDelayad(true);
    const load = loaded.current;
    const advertisement = document.getElementById('advertisemented');

    if (load && advertisement) {
      load.style.display = 'none';
      advertisement.style.display = 'block';
    }
  }, [setIsGameOver, setDelayad, loaded, setLoading]);


  useEffect(() => {
    if (refreshAdd == true) {
      setTimeout(() => {
        setRefreshAdd(false)
      }, 2000);
    }
  }, [refreshAdd])


  const openFullscreen = () => {
    const elem = document.getElementById('fullscreenButton');
    if (elem !== null) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { // Firefox
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    }
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozExitFullscreen) {
      document.mozExitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  useEffect(() => {
    addEventListener("LevelOver", handleGameOver);
    return () => {
      removeEventListener("LevelOver", handleGameOver);
    };
  }, [addEventListener, removeEventListener, handleGameOver]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("If you go back, You will lose your game progress.")) {
        setfinishStatus(true)
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false)
      }
    }
  }

  useEffect(() => {
    // window.scrollTo(0, 0);
    if (containerRef.current) {

      containerRef.current.scrollIntoView({ behavior: "instant" });
    }
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
      // video.removeEventListener("ended", handleVideoEnded);
    };
  }, []);


  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
      const isAndroid = /Android/.test(navigator.userAgent);
      if (!!document.fullscreenElement === false && isAndroid) {
        setChangeVisibility(true)
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('msfullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('msfullscreenchange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    if (finishStatus) {
      gohomescreen()
    }
    else {
      window.history.pushState(null, null, window.location.pathname);
    }
  }, [finishStatus, unload])


  useEffect(() => {
    // window.scrollTo(0, 0);
    if (containerRef.current) {

      containerRef.current.scrollIntoView({ behavior: "instant" });
    }
  }, []);

  const handleButtonClick = () => {
    // ("handleButtonClick clicked");
    setVideoVisible(false)
    setShowButton(true);
   
  };
  // useEffect(() => {
  //   if (showButton && isLoaded) {
  //     // Call setButtonRed when showButton is true and isLoaded is true
  //     setButtonRed((prevValue) => !prevValue);
  //   }
  // }, [showButton, isLoaded]);
  // useEffect(() => {
  //   if (videoVisible === false || delayadvertisement === false || showButton === true) {
  //     // setButtonRed((prevValue) => !prevValue);
  //     openFullscreen()
  //   }
  // }, [videoVisible,delayadvertisement, showButton]);

  const handleButtonClicks = () => {
    // ("handleButtonClick clicked");
    setDelayadvertisement(false)
    setShowButtons(true);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {

      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const mobilescreenfullview = () => {
    handleOrientationChange()

    setShowplaybutton(false)
    setDelayadvertisement(true)
    setButtonCounter(5);

    const interval = setInterval(() => {

      setButtonCounter((prevCounter) => {
        if (prevCounter > 1) {
          return prevCounter - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);
    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      clearInterval(interval);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };

  }


  const handleOrientationChange = useCallback((event) => {
    if (event) {
      // Prevent the default behavior to avoid page refresh
      event.preventDefault();
    }

    const isLandscape = window.orientation === 90 || window.orientation === -90;
    setShowRotateYourScreen(!isLandscape)

  }, []);


  const handlePlayNowClick = () => {
    // Set showsplash to false when the button is clicked
    setShowsplash(false);
    setButtonCounter(5);

    const interval = setInterval(() => {

      setButtonCounter((prevCounter) => {
        if (prevCounter > 1) {
          return prevCounter - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };

  };
  useEffect(() => {
    setDelayadvertisement(true)
  }, [])

  useEffect(() => {
    if (showplaybutton === false || delayadvertisement === true || showButtons === true) {
      openFullscreen()
    }
  }, [showplaybutton, delayadvertisement, showButtons])

  useEffect(() => {
    document.title = 'Pacmania - Play on Revolution Games';
  }, []);


  useEffect(() => {
    const isIpad = /iPad/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    setIsPadUnity(isIpad)
    const isTouchDesktop = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0 && !(/iPad/.test(navigator.platform));

    const isIphone = /iPhone/.test(navigator.userAgent);
    setIsiPhoneUnity(isIphone)
    const isAndroid = /Android/.test(navigator.userAgent);
    setIsAndroidUnity(isAndroid)

    if (isIpad || isIphone || isAndroid) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

  }, []);

  const StartinggamePlay = useCallback((message) => {
    setStartingflow(true)
  }, []);

  useEffect(() => {
    addEventListener("ShowAds", StartinggamePlay);
    return () => {
      removeEventListener("ShowAds", StartinggamePlay);
    };
  }, [addEventListener, removeEventListener, StartinggamePlay]);


  function startSendMsgAndroid() {
    sendMessage("ResponseFromWeb", "GetResolution", "AndroidUnity");
  }
  function startSendMsgiPhone() {
    sendMessage("ResponseFromWeb", "GetResolution", "iPhoneUnity");
  }

  const backNavigation = () => {
    gohomescreen()
  }

  const [divHeight, setDivHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const height = contentRef.current.getBoundingClientRect().height;
      setDivHeight(height);
    }
  }, [contentRef]);

  const [changevisibility, setChangeVisibility] = useState(false);

  useEffect(() => {
    if (showRotateYourScreen === true || changevisibility === true) {
      pauseMessage()
    }
    else {
      unpauseMessage()
    }
  }, [showRotateYourScreen, sendMessage, changevisibility])


  const handleResumeButtonClick = () => {

    unpauseMessage()
    setChangeVisibility(false)
    if (isAndroidunity) {
      openFullscreen()
    }
  }


  function pauseMessage() {
    sendMessage("ResponseFromWeb", "PauseFunction", "Hi from web");
  }

  function unpauseMessage() {
    sendMessage("ResponseFromWeb", "UnPauseFunction", "Hi from web");
  }
  const [progression, setProgression] = useState(0);

  // useEffect(() => {
  //   let intervalId;

  //   // Check if loadingProgression is greater than or equal to 90%
  //   if (loadingProgression >= 0.9) {
  //     // Set progression to 90
  //     setProgression(90);

  //     // Schedule the manual progression increase every 100 milliseconds
  //     intervalId = setInterval(() => {
  //       // Increment progression beyond 90 until 100%
  //       if (progression < 101) {
  //         setProgression(prevProgress => Math.min(prevProgress + 1, 101));
  //       }
  //     }, 100); // 10 milliseconds interval for smoother transition
  //   } else {
  //     // If loadingProgression is less than 90%, calculate normally
  //     setProgression(Math.round(loadingProgression * 100));
  //   }

  //   return () => clearInterval(intervalId); // Cleanup interval on unmount or update
  // }, [loadingProgression]);

  {/* Sidhu Modified Progress Bar - 22.04.2024 */ }

  useEffect(() => {
    if (showButton || showButtons) {
      if (loadingProgression > 0 && Math.round(loadingProgression * 100) < 90 && isLoaded === false && loadingProgressionLoadedFirst90) {
        setLoadingProgressionValue(Math.round(loadingProgression * 100))
      } else if (isLoaded) {
        setLoadingProgressionValue(100);
      } else {
        if (isLoaded === false && !loadingProgressionLoadedFirst90) {
          setLoadingProgressionLoadedFirst90(true)
        }
      }
      if (loadingProgressionValue >= 80 && !isLoaded) {
        const interval = setInterval(() => {
          setLoadingProgressionValue((prevPercent) => {
            const newPercent = Math.min(prevPercent + 3, 100);
            return newPercent;
          });
          //updateLoadingRandomText();
        }, 300); // Update text every second for smoother transition

        return () => clearInterval(interval);
      }
    }
  }, [showButton, showButtons, isLoaded, loadingProgression, loadingProgressionValue, loadingProgressionLoadedFirst90, percent])
const full = () =>
{
  openFullscreen()
}
  
  return (
    <>
      <div className="App-header">
        <div ref={containerRef} />

        <div className="headerbackground d-none d-sm-none d-md-none d-lg-block" style={{ background: "#222222", display: (showplaybutton === false && isMobile === true) && "none" }}>
          <div className=" " style={{ height: "48px" }}>

            <div className="row m-0 " >
              <div className="col-lg-4 col-sm-4 col-md-4 col-5 ps-lg-5 ps-sm-3 ps-md-3 ps-3" >
                <div className="d-flex align-items-start justify-content-start" style={{ cursor: "pointer" }} onClick={gohomescreen} >
                  <img src={rgnamelogos} alt="Revolution Games" style={{ objectFit: "fill", width: "100px", height: "55px" }} />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mt-2 pb-1 pt-0 d-none d-lg-block d-sm-none d-md-block">

                <div className="d-flex align-items-center justify-content-center">
                  <h4 className="text-white" style={{ fontWeight: "bold" }}>Pacmania</h4>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-8 col-7 mt-1 pe-3  pb-1 pt-1">
                <div className="d-flex align-items-end justify-content-end">
                  <img src={close} alt="Close" style={{ objectFit: "fill", width: "35px" }} onClick={gohomescreen} />
                </div>
              </div>

            </div >
          </div>
          {/* <div style={{height:"5px", background:"black"}}>

        </div> */}
        </div>
        
        <div className={`${(showplaybutton === false) ? "px-lg-0 px-sm-0 px-md-0 px-0 py-0" : "px-lg-0 px-sm-0 px-md-0 px-0 py-0"}`}>
          <div className="d-flex flex-row col-lg-12 col-sm-12 col-md-12 ">

            {/* <div className=" " style={{ border: "1px solid black", display: (showplaybutton === false && isMobile === true) && "none", width: isMobile === true ? "0%" : "16.6%" }}>
              <div className={`${isMobile === true ? "px-0 mt-2" : "px-2 mt-2"}} }`}  >
                <BottomListGameLeft searchQuery={searchQuery} sendMessageToUnity={sendmessagetounity} excludeIds="15" height={divHeight} />
              </div>
            </div> */}
            <div ref={contentRef} style={{ width: isMobile === true ? "100%" : "100%" }}  >
              {!isMobile && (
                <div  className="  mb-75 d-flex align-items-center justify-content-center" style={{ paddingTop: "0%", paddingBottom: "0%", background: "#222222", position: "relative" }}>
                  {videoVisible && (
                    <>

                      <div style={{
                        width: '100%', height: '100%', position: "relative"
                      }} className="paddingads"  >
                        <div className="d-flex flexing align-items-center justify-content-center" style={{ textAlign: "center",  width: '100%', height: '100%',  }}   >

                          {delayadvertisement && (
                            <>
                              <h6 className="text-white " style={{ fontWeight: "bold" }}>Advertisement</h6>
                              <div className="" style={{ zIndex: (showsplash === false) ? "1" : "0" }} >

                                <Advertisement3 />
                              </div>
                            </>)}
                          {delayadvertisement && (
                            <>
                              {buttonCounter > 0 ? <div className="mt-1"> <p className="text-white" style={{ fontWeight: "bold", textAlign: "center" }}>Continue in {buttonCounter}</p></div>
                                :
                                <>
                                  <div className="mt-1" style={{ textAlign: "center", zIndex: (showsplash === false) ? "1" : "0", cursor: "pointer" }} >
                                    <img src={continueyellow2} alt="playbutton" onClick={handleButtonClick} className="buttonyellowcontinue" />
                                  </div>
                                </>
                              }
                            </>)}
                        </div>


                        <div style={{ position: "absolute", width: '100%', height: '100%', top: "0%" }}>

                          {showsplash && (
                            <>
                              <div className="d-flex flex-column align-items-center justify-content-center" style={{ width: '100%', height: '100%', backgroundColor: "#222222" }} >
                                <Suspense fallback={<img src={rgilogo} style={{ width: "120px", height: "80px" }} />}>
                                  <StartingSplashDiv gameImage={pacmania} gameName="Pacmania" onPlayNowClick={handlePlayNowClick} />
                                </Suspense>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}


                  {showButton && (
                    <div   style={{ width: isLoaded == false ? "95%" : "95% ", height: isLoaded == false ? "90vh" : "90vh", paddingTop: isLoaded === false ? "10%" : "0%", paddingBottom: isLoaded === false ? "10%" : "0%", textAlign: "center", overflow:"hidden" }}>
                      {isLoaded == false && (

                        < >
                          <img src={pacmania} className="imagesplash mb-2" />
                          <div>
                            <span className="text-white fw-bold mb-2" style={{ fontSize: "24px" }}>Pacmania</span>
                          </div>
                          {/* Sidhu Modified Web Progress Bar - 22.04.2024 */}

                          <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                            {loadingProgression == 0 && (
                              <div className="demo-container mt-2">
                                <div className="progress-bar-style rounded">
                                  <div className="progress-bar-value"></div>
                                </div>
                              </div>
                            )}
                            {loadingProgression > 0 && (
                              <div className="mt-2" >
                                < Progress
                                  status="active"
                                  theme={{ active: { color: '#f1c70a' } }}
                                  percent={(loadingProgressionValue * 4.5) < 100 ? (loadingProgressionValue * 4.5) : 100} />
                              </div>
                            )}
                          </div>
                          {(loadingProgressionValue * 4) < 101 ? (
                            <p className="text-white">
                              Please wait. Building Your Gaming World!... {(loadingProgressionValue * 4.5) < 100 ? (loadingProgressionValue * 4.5) : 100}%
                            </p>
                          ) : (
                            <p className="text-white">
                              Starting the Game...
                            </p>
                          )}
                        </>

                      )}
                      {/* {showUnity && ( */}
                      <div id="fullscreenButton" style={{ position: "relative" }}>

                        <Unity
                          style={{
                            width: "95vw",
                            height: "87vh",
                            unitySelf: "center",
                            alignSelf: "center",
                            marginTop: "12px",
                          }}
                          unityProvider={unityProvider}
                        />


                        <div style={delayad && isGameOver ? { position: "absolute", top: "12px", width: "100%", height: "100%", left: "0%" } : { position: "absolute", top: "-10000000px" }}>
                          <div style={{ position: "relative" }}>
                            <img src={pacmania} style={{ width: "100%", height: isFullscreen ? windowHeight : "100%", opacity: "0.9" }} />
                            <div style={{ position: "absolute", bottom: "0%", right: "0%", paddingBottom: "10%", paddingTop: "10%", background: "#222222", opacity: "0.9", width: "100%", height: "100%" }}>
                              {/* <div style={{ position: "relative" }}> */}
                              <div className="d-flex flex-column align-items-center justify-content-center" style={{ position: "absolute", margin: "auto", left: 0, right: 0, top: 0, bottom: 0 }}>
                                <h6 className="text-white" style={{ fontWeight: "bold" }}>Advertisement</h6>


                                {!refreshAdd && (<div id="advertisemented" >
                                  <Advertisement3 />
                                </div>
                                )}



                                {/* {buttonCounters > 0 ? (
                                                                            <div className="mt-1">
                                                                                <p className="text-white" style={{ fontWeight: "bold", textAlign: "center" }}>Continue in {buttonCounters}</p>
                                                                            </div>
                                                                        ) : ( */}
                                <div className=" mt-1" style={{ textAlign: "center", cursor: "pointer" }}>
                                  <img src={continueyellow2} alt="playbutton" onClick={Advertisementafterplay} className="buttonyellowcontinue" />
                                </div>
                                {/* )} */}
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>

                        {isFullscreen && (
                          <BiExitFullscreen onClick={closeFullscreen} style={{ position: "absolute", bottom: "7%", right: "2%", width: "30px", height: "30px" }} color="black" />
                        )}
                      </div>
                      {/* )} */}
                    </div>
                  )}
                </div>
              )}
              {isMobile && (
                <div id={isAndroidunity === true ? "fullscreenButton" : undefined} className=" d-flex align-items-center justify-content-center" style={{ background: "#222222", overflow: "hidden" }}  >

                  {/* Mobile Play now, Advertisement, Rotate your screen, Progress bar and Unity */}

                  {/* Mobile Play now*/}
                  {(showplaybutton) && (
 <div style={{ height: windowHeight, width: "100%", overflow: "hidden" }}>
 <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
   <div className="d-flex flex-column align-items-center justify-content-center">
     <img src={pacmania} alt="loaderlogo" width="240px" height="135px" style={{ borderRadius: "5px" }} />
     <button className="btn btn-warning fw-bold mt-2" onClick={mobilescreenfullview}>Play Now</button>
   </div>
 </div>
</div>
                 
                  )}
                  {/* <div id={(isAndroidunity === true) && "fullscreenButton"} style={{ background: "#222222" }}> */}
                  <div style={{ position: "relative", touchAction: "none" }}>
                    {/* Rotate your screen*/}
                    {(delayadvertisement && !showplaybutton) && (
                      <div style={{
                        width: windowWidth, height: windowHeight
                      }} className={`${(showplaybutton === true) ? "d-flex flex-column align-items-center justify-content-center" : "d-flex flex-row align-items-center justify-content-center"}`} >

                        <div className="col-7 d-flex align-items-end justify-content-end" >
                          <Advertisement3 />
                        </div>
                        {/*  Continue + counter OR Countinue */}
                        <>
                          {buttonCounter > 0 ? <div className="col-5 d-flex align-items-start justify-content-start p-3"> <p className="text-white" style={{ fontWeight: "bold", textAlign: "center" }}>Continue in {buttonCounter}</p></div>
                            :
                            <>
                              <div className="col-5 d-flex align-items-start justify-content-start p-3" style={{ textAlign: "center", cursor: "pointer", zIndex: (showsplash === false) ? "1" : "0" }} >
                                <img src={continueyellow2} alt="playbutton" onClick={handleButtonClicks} className="buttonyellowcontinue" />
                              </div>
                            </>
                          }
                        </>
                      </div>

                    )}

                    {/*  Advertisement */}

                  </div>
                  {(showRotateYourScreen) && (
                    <div className="d-flex flex-column align-items-center justify-content-center" style={{ width: "100vw", height: "100vh", position: "absolute", top: "0px", background: "#222222", zIndex: 11111111, touchAction: "none" }}>
                      <MdScreenRotation style={{ color: "white", width: "150px", height: "150px", }} />
                      <h1 className="text-white">Rotate your screen</h1>
                    </div>
                  )}

                  {/* Progress bar and Unity */}
                  {showButtons && (
                    <>
                      <div style={{ width: isLoaded == false ? "100%" : "100% ", height: isLoaded == false ? windowHeight : windowHeight, overflow: "hidden", position: "relative", touchAction: "none" }}>
                        {isLoaded == false && (

                          <div style={{ position: "absolute", margin: "auto", left: 0, right: 0, top: 0, bottom: 0 }} className="d-flex flex-column align-items-center justify-content-center">
                            <img src={pacmania} className="imagesplash mb-2" />
                            <div>
                              <span className="text-white fw-bold mb-2" style={{ fontSize: "24px" }}>Pacmania</span>
                            </div>
                            {/* Sidhu Modified Mobile Progress Bar - 22.04.2024 */}
                           
                            <div style={{ paddingLeft: "10%", paddingRight: "10%", width: "100%" }}>
                              {loadingProgression <= 0.01 && (
                                <div className="demo-container mt-2">
                                  <div className="progress-bar-style rounded">
                                    <div className="progress-bar-value"></div>
                                  </div>
                                </div>
                              )}
                              {loadingProgression > 0.01 && (
                                <div className="mt-2" >
                                  < Progress
                                    status="active"
                                    theme={{ active: { color: '#f1c70a' } }}
                                    percent={(loadingProgressionValue * 5) < 100 ? (loadingProgressionValue * 5) : 100} />
                                </div>
                              )}
                            </div>
                            {(loadingProgressionValue * 5) < 101 ? (
                              <p className="text-white">
                                Please wait. Building Your Gaming World!...
                              </p>
                            ) : (
                              <p className="text-white">
                                Starting the Game...
                              </p>
                            )}
                          </div>
                        )}

                        <div className="d-flex align-items-center justify-content-center" style={{ overflow: "hiddden" }}>
                          <Unity
                            style={{
                              width: (isIpadUnity === true || isAndroidunity === true) && "100vw",
                              aspectRatio: "16/9",
                              unitySelf: "center",
                              alignSelf: "center",
                              height: windowHeight,
                              //padding: isIpadUnity === true && "3%",
                              visibility: isLoaded ? "visible" : "hidden"
                            }}
                            unityProvider={unityProvider}
                          />
                          {/* Level completion Advertisement */}
                          <div style={delayad && isGameOver ? { position: "absolute", bottom: "0px", width: "100%", height: "100%", left: "0%", textAlign: "center" } : { position: "absolute", top: "-10000000px" }}>
                            <div style={{ position: "relative" }}>
                              <img src={pacmania} style={{ aspectRatio: showRotateYourScreen === false && "16/9", width: (showRotateYourScreen === true || isIpadUnity === true || isAndroidunity === true) && "100vw", height: showRotateYourScreen === true ? "100vh" : windowHeight, opacity: "0.9" }} />
                              <div style={{ position: "absolute", bottom: "0%", right: "0%", background: "#222222", opacity: "0.9", width: "100%", height: "100%" }}>
                                {/* <div style={{ position: "relative" }}> */}
                                <div className={`${(showplaybutton === true) ? "d-flex flex-column align-items-center justify-content-center" : "d-flex flex-row align-items-center justify-content-center"}`} style={{ position: "absolute", margin: "auto", left: 0, right: 0, top: 0, bottom: 0 }}>


                                  {!refreshAdd && (<div id="advertisemented" className="mx-2 my-3" >

                                    <Advertisement3 />

                                  </div>
                                  )}



                                  {/* {buttonCounters > 0 ? (
                                                                                        <div className="mx-2">
                                                                                            <p className="text-white" style={{ fontWeight: "bold", textAlign: "center" }}>Continue in {buttonCounters}</p>
                                                                                        </div>
                                                                                    ) : ( */}
                                  <div className=" mx-2" style={{ textAlign: "center", cursor: "pointer" }}>
                                    <img src={continueyellow2} alt="playbutton" onClick={Advertisementafterplay} className="buttonyellowcontinue" />
                                  </div>
                                  {/* )}  */}
                                </div>
                                {/* </div> */}
                              </div>
                            </div>
                          </div>

                        </div>
                        <div style={{ position: "absolute", top: "22%", left: 0, }} >
                          <button onClick={backNavigation} className="backnavigationbutton">
                            <SlArrowLeft color="white" />
                            <img src={rgblacklogo} style={{ width: "25px", height: "25px" }} />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {(changevisibility && !showplaybutton) && (
                    <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: windowHeight, width: "100vw", position: "absolute", top: "0px", background: "#222222", zIndex: 111111111111, touchAction: "none", overflow: "hidden" }}>
                      <img src={pacmania} alt="loaderlogo" width="240px" height="135px" style={{ borderRadius: "5px" }} />
                      <span className="text-white fw-bold mb-2" style={{ fontSize: "24px" }}>Pacmania</span>
                      <button className="btn btn-warning fw-bold mt-2" onClick={handleResumeButtonClick}>Continue Playing</button>
                    </div>
                  )}
                </div>

                // </div>
              )}

              {/* <div className="pb-2 pt-1 mt-1" style={{ background: "#222222", display: (showplaybutton === false && isMobile === true) && "none" }}>

                <div className="d-flex align-items-between justify-content-between px-3">
                  <BottomMenu isButtonRed={isButtonRed} onButtonClick={openFullscreen} gameId="15" gameImage={pacmania} gameName="Pacmania" link="/pacmania" />
                </div>
              </div> */}

              {/* </div> */}
              {/* <div className="mt-3 " style={{ display: (showplaybutton === false && isMobile === true) && "none" }}>
                <Addadds />
              </div> */}
              {/* <div style={{ textAlign: "justify", display: (showplaybutton === false && isMobile === true) && "none" }} className="text-white px-3 mt-3">
                <h6 className="mb-3" style={{ fontSize: "24px", fontWeight: "600" }}>Pacmania</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>"Pacmania" is a classic arcade game that involves navigating a hero character named Pac Man through a maze while eating pellets and avoiding ghosts.Playing Pac-Man is a classic and enjoyable experience.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>The primary goal is to navigate Pac-Man through a maze, eating all the pellets while avoiding ghosts. Use the arrow keys on your keyboard or touch controls in mobile device to control Pac-Man.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>Players must outmaneuver the ghosts, who relentlessly chase Pac-Man throughout the maze. If a ghost catches Pac-Man, a life is lost. However, players can turn the tables by consuming power pellets scattered throughout the maze, temporarily transforming Pac-Man into a powerful entity capable of eating ghosts for extra points.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>The game features multiple levels, each with its own unique maze layout and increasingly challenging gameplay. As players progress, the speed and complexity of the game increase, providing a thrilling and addictive experience.</h6>
                <h6 className="mb-3" style={{ fontSize: "15px", fontWeight: "400" }}>"Pac-Man" has become a cultural icon and is widely regarded as one of the greatest video games of all time. Its simple yet engaging gameplay, vibrant graphics, and memorable characters have captivated players for generations, making it a timeless classic enjoyed by millions worldwide</h6>
                <h6 style={{ fontSize: "18px", }} className="fontweight mb-3">How To Play?</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">Playing <b>Pacmania</b> is very easy.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">1. You need to click the <b>Play</b> Button to start.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3">2. In Pacmania, you'll use the controls to <b>move</b> Pac-Man in the desired direction: <b>up, down, left, or right.</b></h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3" >3. Collect the <b>eatables</b> to increase your <b>score</b> and <b>powers</b> to make the <b>enemy eatable.</b></h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3" >4. You can get different <b>skins</b> in the skins option.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }} className="mb-3" >5. Enjoy the classic arcade experience and have fun gobbling up <b>pellets</b> as Pac-Man!</h6>
                <iframe width="650" height="350" src="https://www.youtube.com/embed/FNv-DhJtWuY" frameBorder="0" allowFullScreen></iframe>


                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Controls</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400", textDecoration: "underline" }}>DESKTOP : </h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>1. <b>Joystick :</b> Use <b>mouse left button</b> to move in the direction you want Pac-Man to move</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>2. <b>Keyboard :</b></h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> * <b>UP:</b> Use the <b>UP arrow</b> Key.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> * <b>Down:</b> Use the <b>DOWN arrow</b> Key.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> *<b> Left:</b> Use the <b>RIGHT arrow</b> Key. </h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> * <b>Right:</b> Use the <b>LEFT arrow</b> key.</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400", textDecoration: "underline" }}>MOBILE : </h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> 1. <b>Swipe:</b>  <b>Swipe</b> in the direction you want Pac-Man to move</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}> 2. <b>Joystick : Drag</b> the joystick in the direction you want Pac-Man to move</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Category</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Arcade, Classic</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Tags</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Classic arcade game, Pac-Man, Maze navigation, Pellet eating, Ghost avoidance, Arrow keys, Touch controls, Outmaneuver ghosts, Power pellets, Extra points, Multiple levels, Unique maze layouts, Increasingly challenging, Thrilling gameplay, Addictive experience, Cultural icon, Greatest video games, Simple gameplay, Vibrant graphics, Memorable characters, Timeless classic.</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Release Date</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>6 February 2024</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Updates</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Build Number : 008</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>6 February 2024</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Developer</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Revolution Games Private Limited</h6>
                <h6 style={{ fontSize: "20px" }} className="fontweight mb-3">Platforms</h6>
                <h6 style={{ fontSize: "15px", fontWeight: "400" }}>Web browser (desktop and mobile)</h6>

              </div> */}
            </div>
            {/* <div className="" style={{ border: "1px solid black", display: (showplaybutton === false && isMobile === true) && "none", width: isMobile === true ? "0%" : "16.6%" }}>

              <div className={`${isMobile === true ? "px-0 mt-2" : "px-2 mt-2"}} }`}  >
                <BottomListGameRight searchQuery={searchQuery} sendMessageToUnity={sendmessagetounity} excludeIds="15" height={divHeight} />
              </div>
            </div> */}
          </div>

        </div>

        {/* <div className="" style={{ display: (showplaybutton === false && isMobile === true) && "none" }}>
          <Othergames  searchQuery={searchQuery} sendMessageToUnity={sendmessagetounity} excludeIds="15" />
        </div>
        <div className="" style={{ display: (showplaybutton === false && isMobile === true) && "none" }}>
          <FooterPortion />
        </div> */}
      </div>
    </>
  );
}

export default PacMania;
