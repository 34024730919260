import React from "react";
import '../App.css';
import { useNavigate } from "react-router-dom";
import enemies1 from '../assests/Enemy Sprite/Enemy1/enemy1.png'
import enemies2 from '../assests/Enemy Sprite/Enemy1/enemy2.png'
import enemies3 from '../assests/Enemy Sprite/Enemy1/enemy3.png'
import enemies4 from '../assests/Enemy Sprite/Enemy1/enemy4.png'
import enemies5 from '../assests/Enemy Sprite/Enemy1/enemy5.png'
import enemies6 from '../assests/Enemy Sprite/Enemy1/enemy6.png'
import enemies7 from '../assests/Enemy Sprite/Enemy1/enemy7.png'
import enemies8 from '../assests/Enemy Sprite/Enemy1/enemy8.png'
import enemies9 from '../assests/Enemy Sprite/Enemy1/enemy9.png'
import enemies10 from '../assests/Enemy Sprite/Enemy1/enemy10.png'
import enemies11 from '../assests/Enemy Sprite/Enemy2/enemy11.png'
import enemies12 from '../assests/Enemy Sprite/Enemy2/enemy12.png'
import enemies13 from '../assests/Enemy Sprite/Enemy2/enemy13.png'
import enemies14 from '../assests/Enemy Sprite/Enemy2/enemy14.png'
import enemies15 from '../assests/Enemy Sprite/Enemy2/enemy15.png'
import enemies16 from '../assests/Enemy Sprite/Enemy2/enemy16.png'
import enemies17 from '../assests/Enemy Sprite/Enemy2/enemy17.png'
import enemies18 from '../assests/Enemy Sprite/Enemy2/enemy18.png'
import enemies19 from '../assests/Enemy Sprite/Enemy2/enemy19.png'
import enemies20 from '../assests/Enemy Sprite/Enemy2/enemy20.png'
import enemies21 from '../assests/Enemy Sprite/Enemy3/enemy21.png'
import enemies22 from '../assests/Enemy Sprite/Enemy3/enemy22.png'
import enemies23 from '../assests/Enemy Sprite/Enemy3/enemy23.png'
import enemies24 from '../assests/Enemy Sprite/Enemy3/enemy24.png'
import enemies25 from '../assests/Enemy Sprite/Enemy3/enemy25.png'
import enemies26 from '../assests/Enemy Sprite/Enemy3/enemy26.png'
import enemies27 from '../assests/Enemy Sprite/Enemy3/enemy27.png'
import enemies28 from '../assests/Enemy Sprite/Enemy3/enemy28.png'
import enemies29 from '../assests/Enemy Sprite/Enemy3/enemy29.png'
import enemies30 from '../assests/Enemy Sprite/Enemy3/enemy30.png'







const Enemies = () => {
    const navigate = useNavigate()


    const itemsenemies = [
        { id: 1, image: enemies1, name: "Blinky" },
        { id: 2, image: enemies2, name: "Plinky" },
        { id: 3, image: enemies3, name: "Inky" },
        { id: 4, image: enemies4, name: "Clyde" },
        { id: 5, image: enemies5, name: "Shadow" },
        { id: 6, image: enemies6, name: "Chompers" },
        { id: 7, image: enemies7, name: "Speedy" },
        { id: 8, image: enemies8, name: "Winky" },
        { id: 9, image: enemies9, name: "Ghostly" },
        { id: 10, image: enemies10, name: "Phantom" },
        { id: 11, image: enemies11, name: "Specter" },
        { id: 12, image: enemies12, name: "Haunter" },
        { id: 13, image: enemies13, name: "Vapor" },
        { id: 14, image: enemies14, name: "Flicker" },
        { id: 15, image: enemies15, name: "Spooky" },
        { id: 16, image: enemies16, name: "Creepy" },
        { id: 17, image: enemies17, name: "Wraith" },
        { id: 18, image: enemies18, name: "Nebula" },
        { id: 19, image: enemies19, name: "Shiver" },
        { id: 20, image: enemies20, name: "Zephyr" },
        { id: 21, image: enemies21, name: "Mystic" },
        { id: 22, image: enemies22, name: "Enigma" },
        { id: 23, image: enemies23, name: "Mirage" },
        { id: 24, image: enemies24, name: "Echo" },
        { id: 25, image: enemies25, name: "Whirlwind" },
        { id: 26, image: enemies26, name: "Seraph" },
        { id: 27, image: enemies27, name: "Ethereal" },
        { id: 28, image: enemies28, name: "Poltergeist" },
        { id: 29, image: enemies29, name: "Mirage" },
        { id: 30, image: enemies30, name: "Luna" },
    ];




    return (

        <>

            <div className="row px-2 rowcol1">
                <h6 className="text-white">Enemies</h6>
                {itemsenemies.map(item => (
                   <div key={item.id} className="d-flex flex-column align-items-center justify-content-center col-1 col-sm-3 col-3 col-md-3 py-3">
                   <img src={item.image} className="sliderimg px-3" alt={item.name} />
                   <small className="text-white" style={{fontWeight:"bold"}}>{item.name}</small>
               </div>
                ))}
            </div>



        </>

    )
}

export default Enemies;