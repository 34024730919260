import React from "react";
import '../App.css';
import { useNavigate } from "react-router-dom";
import hero1 from '../assests/HeroSprites/heroes1.png'
import hero2 from '../assests/HeroSprites/heroes2.png'
import hero3 from '../assests/HeroSprites/heroes3.png'
import hero4 from '../assests/HeroSprites/heroes4.png'
import hero5 from '../assests/HeroSprites/heroes5.png'
import hero6 from '../assests/HeroSprites/heroes6.png'
import hero7 from '../assests/HeroSprites/heroes7.png'
import hero8 from '../assests/HeroSprites/heroes8.png'
import hero9 from '../assests/HeroSprites/heroes9.png'
import hero10 from '../assests/HeroSprites/heroes10.png'

const Heroes = () => {
    const navigate = useNavigate()

    
    const itemsheroes = [
        { id: 1, image: hero1 },
        { id: 2, image: hero2},
        { id: 3, image: hero3,  },
        { id: 4, image: hero4,  },
        { id: 5, image: hero5, },
        { id: 6, image: hero6 },
        { id: 7, image: hero7},
        { id: 8, image: hero8,  },
        { id: 9, image: hero9,  },
        { id: 10, image: hero10, },
       
    ];

  
  

    return (

        <>
        
                <div className="row px-2 rowcol1">
                <h6 className="text-white">Heroes</h6>
                        {itemsheroes.map(item => (
                            <div className="col-1 col-sm-3 col-3 col-md-3">
                                    <img key={item.id} src={item.image} className="sliderimg px-3 py-2" alt={item.name}  />
                            </div>
                        ))}
                </div>
               


        </>

    )
}

export default Heroes;