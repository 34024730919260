import React from "react";
import '../App.css';
import { useNavigate } from "react-router-dom";
import eat1 from '../assests/Eatables/eatables1.png'
import eat2 from '../assests/Eatables/eatables2.png'
import eat3 from '../assests/Eatables/eatables3.png'
import eat4 from '../assests/Eatables/eatables4.png'
import eat5 from '../assests/Eatables/eatables5.png'
import eat6 from '../assests/Eatables/eatables6.png'
import eat7 from '../assests/Eatables/eatables7.png'
import eat8 from '../assests/Eatables/eatables8.png'
import eat9 from '../assests/Eatables/eatables9.png'
import eat10 from '../assests/Eatables/eatables10.png'
import eat11 from '../assests/Eatables/eatables11.png'
import eat12 from '../assests/Eatables/eatables12.png'
import eat13 from '../assests/Eatables/eatables13.png'
import eat14 from '../assests/Eatables/eatables14.png'
import eat15 from '../assests/Eatables/eatables15.png'
import eat16 from '../assests/Eatables/eatables16.png'
import eat17 from '../assests/Eatables/eatables17.png'
import eat18 from '../assests/Eatables/eatables18.png'
import eat19 from '../assests/Eatables/eatables19.png'
import eat20 from '../assests/Eatables/eatables20.png'

const Eatables = () => {
    const navigate = useNavigate()

    
    const itemseatables = [
        { id: 1, image: eat1 },
        { id: 2, image: eat2},
        { id: 3, image: eat3,  },
        { id: 4, image: eat4,  },
        { id: 5, image: eat5, },
        { id: 6, image: eat6 },
        { id: 7, image: eat7},
        { id: 8, image: eat8,  },
        { id: 9, image: eat9,  },
        { id: 10, image: eat10, },
        { id: 11, image: eat11 },
        { id: 12, image: eat12},
        { id: 13, image: eat13,  },
        { id: 14, image: eat14,  },
        { id: 15, image: eat15, },
        { id: 16, image: eat16 },
        { id: 17, image: eat17},
        { id: 18, image: eat18,  },
        { id: 19, image: eat19,  },
        { id: 20, image: eat20, },
    ];

  
  

    return (

        <>
        
                <div className="row px-2 rowcol1">
                <h6 className="text-white">Eatables</h6>
                        {itemseatables.map(item => (
                            <div className="col-1 col-sm-3 col-3 col-md-3">
                            <img key={item.id} src={item.image} className="sliderimg  p-3" alt={item.name}  />
                            </div>
                        ))}
                </div>
               


        </>

    )
}

export default Eatables;