import React, { useState, useEffect, useRef } from "react";
import '../App.css';
import HeaderPortion from "./headerlogo";
import Banner from "./bannersetup";
import Description from "./description";
import FooterPortion from "./footersection";
import Othergames from "../gamescreen/othergames";
import Eatables from "../fivethings/eatables";
import Heroes from "../fivethings/heroes";
import Enemies from "../fivethings/enemies";
import Powerups from "../fivethings/powerups";
import Mazes from "../fivethings/maze";
import ScreenShot from "./screenshotimages";
import bannerweb from '../assests/images/webpacmaniabanner.jpg'
import bannermobile from '../assests/images/mobilepacmaniabanner.jpg'
import { useNavigate } from "react-router-dom";
import playnows from '../assests/images/blueplaybtn.png'

const JoinCode = ({onButtonClick}) => {


    const containerRef = useRef(null);



    const descriptionRef1 = useRef(null);
    const descriptionRef2 = useRef(null);
    const descriptionRef3 = useRef(null);
    const descriptionRef4 = useRef(null);
    const descriptionRef5 = useRef(null);
    const descriptionRef6 = useRef(null);




    useEffect(() => {
        window.addEventListener('load', async function (event) {
            if (containerRef.current) {
                containerRef.current.scrollIntoView({ behavior: "instant" });
            }
        });
        const handleNavClick1 = () => {
            if (descriptionRef1.current) {
                descriptionRef1.current.scrollIntoView({ behavior: "smooth" });
            }
        };

        const navElement1 = document.querySelector(".your-nav-class1");
        if (navElement1) {
            navElement1.addEventListener("click", handleNavClick1);
        }

        const handleNavClick2 = () => {
            if (descriptionRef2.current) {
                descriptionRef2.current.scrollIntoView({ behavior: "smooth" });
            }
        };
        const navElement2 = document.querySelector(".your-nav-class2");
        if (navElement2) {
            navElement2.addEventListener("click", handleNavClick2);
        }

        const handleNavClick3 = () => {
            if (descriptionRef3.current) {
                descriptionRef3.current.scrollIntoView({ behavior: "smooth" });
            }
        };
        const navElement3 = document.querySelector(".your-nav-class3");
        if (navElement3) {
            navElement3.addEventListener("click", handleNavClick3);
        }
        const handleNavClick4 = () => {
            if (descriptionRef4.current) {
                descriptionRef4.current.scrollIntoView({ behavior: "smooth" });
            }
        };
        const navElement4 = document.querySelector(".your-nav-class4");
        if (navElement4) {
            navElement4.addEventListener("click", handleNavClick4);
        }
        const handleNavClick5 = () => {
            if (descriptionRef5.current) {
                descriptionRef5.current.scrollIntoView({ behavior: "smooth" });
            }
        };
        const navElement5 = document.querySelector(".your-nav-class5");
        if (navElement5) {
            navElement5.addEventListener("click", handleNavClick5);
        }
        const handleNavClick6 = () => {
            if (descriptionRef6.current) {
                descriptionRef6.current.scrollIntoView({ behavior: "smooth" });
            }
        };
        const navElement6 = document.querySelector(".your-nav-class6");
        if (navElement6) {
            navElement6.addEventListener("click", handleNavClick6);
        }


        return () => {
            if (navElement1) {
                navElement1.removeEventListener("click", handleNavClick1);
            }

            if (navElement2) {
                navElement2.removeEventListener("click", handleNavClick2);
            }
            if (navElement3) {
                navElement3.removeEventListener("click", handleNavClick3);
            }

            if (navElement4) {
                navElement4.removeEventListener("click", handleNavClick4);
            }
            if (navElement5) {
                navElement5.removeEventListener("click", handleNavClick5);
            }
            if (navElement6) {
                navElement6.removeEventListener("click", handleNavClick6);
            }

        };
    }, []);
    return (

        <>

            <div className="App-header">
                <div ref={containerRef} />
                <div className="headerbackground" style={{ background: "#222222" }}>
                    <HeaderPortion scrollTargetRef1={descriptionRef1} scrollTargetRef2={descriptionRef2} scrollTargetRef3={descriptionRef3} scrollTargetRef4={descriptionRef4} scrollTargetRef5={descriptionRef5} scrollTargetRef6={descriptionRef6} scrollRef={containerRef} />
                </div>
                <div style={{ backgroundColor: "black" }}>
                <div className="d-none d-sm-none d-md-none d-lg-block">
                <div style={{ background: "black", position: "relative" }} >
                    <div className="slider-container">
                        <img src={bannerweb} className="sliderimg" />
                    </div>
                    <div style={{ position: "absolute", left: "4%", bottom: "2%" }}>
   {/* <img src={playnow} width="198px" style={{ cursor: "pointer" }} onClick={pacmaniaplay}/> */}
   <img src={playnows} width="198px" style={{ cursor: "pointer" }} onClick={onButtonClick}/>                    </div>
                </div>
            </div>


            <div className="d-block d-sm-block d-md-block d-lg-none">
                <div style={{ background: "black"}} >
                    <div className="slider-container">
                        <img src={bannermobile} className="sliderimg" />

                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-2" onClick={onButtonClick} >
                        <img src={playnows} width="142px" height="34px" style={{ cursor: "pointer" }} />
                    </div>
                </div>
            </div>
                </div>
                <div style={{ backgroundColor: "black" }}   >
                    <Description />
                </div>
                <div style={{ backgroundColor: "black" }}   >
                    <ScreenShot />
                </div>
                <div className="py-3" ref={descriptionRef1} >
                    <Mazes />
                </div>
                <div className="py-3" ref={descriptionRef2} >
                    <Heroes />
                </div>
                <div className="py-3" ref={descriptionRef3} >
                    <Enemies />
                </div>
                <div className="py-3" ref={descriptionRef4} >
                    <Powerups />
                </div>

                <div className="py-3" ref={descriptionRef5} >
                    <Eatables />
                </div>
                <div style={{ backgroundColor: "black" }} ref={descriptionRef6}  >
                    <Othergames />
                </div>
                <div  >
                    <FooterPortion />
                </div>

            </div>

        </>

    )
}

export default JoinCode;