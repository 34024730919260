import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './stylecss/styles.css'
import JoinCode from "./headerfooter/codejoins";
import PacMania from "./gamescreen/pacmania";
import MainCode from "./headerfooter/mainflow";


class AppRouter extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }


    render() {
        return (
            <Router>

                <Routes>
                    {/* <Route exact path="/" element={<JoinCode />} />
                    <Route exact path="/pacmania" element={<PacMania />} /> */}
                     <Route exact path="/" element={<MainCode />} />
                  



                </Routes>

            </Router>
        );
    }
}

export default AppRouter;