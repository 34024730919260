import React, { useState, useRef, useEffect } from "react";
import '../App.css';
import rgnamelogos from '../assests/images/rginamelogowhite.webp'
import appicon from '../assests/images/pacmaniaappicon.png'
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from 'react-icons/gi'

const HeaderPortion = ({ scrollTargetRef1, scrollTargetRef2, scrollTargetRef3, scrollTargetRef4, scrollTargetRef5, scrollTargetRef6 }) => {

    const navigate = useNavigate();

    const gohomescreen = () => {
        navigate('/');
    }

    // const scrollToSection1 = () => {
    //     if (scrollTargetRef1 && scrollTargetRef1.current) {
    //         scrollTargetRef1.current.scrollIntoView({ behavior: "smooth" });

    //     }
    // };

    const [isFileOpen, setIsFileOpen] = useState(false);

    const toggleFile = () => {
        setIsFileOpen(!isFileOpen);

    };


    const scrollToSection1 = () => {
        setIsFileOpen(false)

        if (scrollTargetRef1 && scrollTargetRef1.current) {
            const scrollOffset = 100;
            const targetPosition = scrollTargetRef1.current.offsetTop - scrollOffset;

            window.scrollTo({
                top: targetPosition,
                behavior: "smooth",
            });
        }
    };



    const scrollToSection2 = () => {
        setIsFileOpen(false)

        if (scrollTargetRef2 && scrollTargetRef2.current) {
            const scrollOffset = 100;
            const targetPosition = scrollTargetRef2.current.offsetTop - scrollOffset;

            window.scrollTo({
                top: targetPosition,
                behavior: "smooth",
            });
        }
    };
    const scrollToSection3 = () => {
        setIsFileOpen(false)

        if (scrollTargetRef3 && scrollTargetRef3.current) {
            const scrollOffset = 100;
            const targetPosition = scrollTargetRef3.current.offsetTop - scrollOffset;

            window.scrollTo({
                top: targetPosition,
                behavior: "smooth",
            });

        }
    };
    const scrollToSection4 = () => {
        setIsFileOpen(false)

        if (scrollTargetRef4 && scrollTargetRef4.current) {
            const scrollOffset = 100;
            const targetPosition = scrollTargetRef4.current.offsetTop - scrollOffset;

            window.scrollTo({
                top: targetPosition,
                behavior: "smooth",
            });
        }
    };


    const scrollToSection5 = () => {
        setIsFileOpen(false)

        if (scrollTargetRef5 && scrollTargetRef5.current) {
            const scrollOffset = 100;
            const targetPosition = scrollTargetRef5.current.offsetTop - scrollOffset;

            window.scrollTo({
                top: targetPosition,
                behavior: "smooth",
            });
        }
    };
    const scrollToSection6 = () => {
        setIsFileOpen(false)

        if (scrollTargetRef6 && scrollTargetRef6.current) {
            const scrollOffset = 100;
            const targetPosition = scrollTargetRef6.current.offsetTop - scrollOffset;

            window.scrollTo({
                top: targetPosition,
                behavior: "smooth",
            });
        }
    };


    return (

        <>


         
             <div className=" " style={{ height: "64px" }}>
                <div className="row m-0 " >
                    <div className="col-lg-2 col-sm-3 col-md-4 col-2 ps-lg-5 ps-sm-3 ps-md-3 ps-3">
                        <div className="d-flex align-items-start justify-content-start" onClick={gohomescreen} >
                            <img src={rgnamelogos} alt="Revolution Games" style={{ objectFit: "fill", width: "120px", height: "75px", cursor: "pointer" }} />
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7 col-9 ps-5  pt-1 ">
                        <div className="d-flex flex-row align-items-center justify-content-center" >
                            <h4 className="text-white d-none d-lg-block d-sm-block d-md-block" style={{ fontWeight: "bold" }}>Pacmania</h4>&nbsp;&nbsp;
                            <img src={appicon} style={{ objectFit: "fill", width: "55px", height: "55px", cursor: "pointer", borderRadius: "8px" }} />

                        </div>
                    </div>
                    <div className="col-lg-3  col-sm-0 col-md-0 pe-5  pb-1  pt-3  d-none d-lg-block d-sm-none d-md-none">
                        <div className="d-flex align-items-end justify-content-end">
                            <h4 className="text-white" style={{ fontWeight: "bold" }}>Revolution Games</h4>
                        </div>
                    </div>
                    <div className=" col-md-2 col-sm-2 col-lg-0  col-1 mt-1 d-block d-lg-none d-sm-block d-md-block d-xl-none pb-1 pt-3 pe-1">
                        <div className="d-flex align-items-end justify-content-end" >

                            <nav id="nav" role="navigation" className="relatived">
                                <ul className="geMenubarContainer">
                                    <li className="geMenubarContainer"> <a className="geItem" onClick={toggleFile} >
                                        <GiHamburgerMenu style={{ width: "22px", height: "22px", color: "#f1c70a" }} />
                                    </a>
                                        {isFileOpen &&

                                            (
                                                <>

                                                    <div className="menuoptionnames" >
                                                        <div stytle={{ background: "black" }}>


                                                            <div className=" mt-1" style={{ cursor: "pointer" }} onClick={scrollToSection1}>
                                                                <p className="text-white" style={{ fontWeight: "bold" }}>Maze</p>
                                                            </div>
                                                            <div className=" " style={{ cursor: "pointer" }} onClick={scrollToSection2}>
                                                                <p className="text-white" style={{ fontWeight: "bold" }}>Heroes</p>
                                                            </div>
                                                            <div className="" style={{ cursor: "pointer" }} onClick={scrollToSection3}>
                                                                <p className="text-white" style={{ fontWeight: "bold" }}>Enemies</p>
                                                            </div>
                                                            <div className="" style={{ cursor: "pointer" }} onClick={scrollToSection4}>
                                                                <p className="text-white" style={{ fontWeight: "bold" }}>PowerUps</p>
                                                            </div>
                                                            <div className="" style={{ cursor: "pointer" }} onClick={scrollToSection5}>
                                                                <p className="text-white" style={{ fontWeight: "bold" }}>Eatables</p>
                                                            </div>
                                                            <div className="" style={{ cursor: "pointer" }} onClick={scrollToSection6} >
                                                                <p className="text-white" style={{ fontWeight: "bold" }} >Other Games</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </>
                                            )


                                        }
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div >
            </div>
              <div className="d-none d-sm-none d-md-none d-lg-block " style={{ height: "32px", background: "black" }}>
                <div className="row px-5">

                    <div className="col-12 px-2">
                        <div className=" d-flex align-items-end justify-content-end">
                            <div className=" mt-1" style={{ cursor: "pointer" }} onClick={scrollToSection1}>
                                <p className="text-white" style={{ fontWeight: "bold" }}>Maze</p>
                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className=" mt-1" style={{ cursor: "pointer" }} onClick={scrollToSection2}>
                                <p className="text-white" style={{ fontWeight: "bold" }}>Heroes</p>
                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className=" mt-1" style={{ cursor: "pointer" }} onClick={scrollToSection3}>
                                <p className="text-white" style={{ fontWeight: "bold" }}>Enemies</p>
                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className=" mt-1" style={{ cursor: "pointer" }} onClick={scrollToSection4}>
                                <p className="text-white" style={{ fontWeight: "bold" }}>PowerUps</p>
                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className=" mt-1" style={{ cursor: "pointer" }} onClick={scrollToSection5}>
                                <p className="text-white" style={{ fontWeight: "bold" }}>Eatables</p>
                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div className=" mt-1" style={{ cursor: "pointer" }} onClick={scrollToSection6} >
                                <p className="text-white" style={{ fontWeight: "bold" }} >Other Games</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default HeaderPortion;